
export const defaultConfiguration = {
    titleClosed: 'Click to chat!',
    titleOpen: 'Let\'s chat!',
    closedStyle: 'chat', // button or chat
    closedChatAvatarUrl: '', // only used if closedStyle is set to 'chat'
    cookieExpiration: 1, // in days. Once opened, closed chat title will be shown as button (when closedStyle is set to 'chat')
    //introMessage: 'Willkommen bei FreeTalk, deinem psychologischem Begleiter.', // 'Welcome to chatbot Bingo. See the instructions on the main page. Good luck! 欢迎来到聊天机器人必应。 请参阅主页上的说明。 祝你好运!\n The chatbot is anonymous and does not save any personal data, including IP address. 聊天机器人是匿名的，不保存任何个人数据，包括 IP 地址。',
    introMessage: 'Welcome to FreeTalk, your AI simulator.', 
    //introMessage: '欢迎来到 FreeTalk，您的心理伴侣.',    
    autoResponse: 'The AI is thinking... AI在思考',
    autoNoResponse: 'It seems that the AI is busy right now. Please tell us how we can ' +
    'contact you, and we will get back to you as soon as we can.',
    placeholderText: 'Send a message...',
    displayMessageTime: true,
    mainColor: '#1f8ceb',
    alwaysUseFloatingButton: false,
    desktopHeight: 450,
    desktopWidth: 370
};
